import React from "react"
import useTranslations from "../../utils/useTranslations"


const CompanyAboutUs = ({ pageContext: {text} }) => {
	const t = useTranslations(text)

	return (
		<>
		<div className="top-section-container black-bg">
			<div className="text-center py-5" >
			<div className="container py-5" >
				<h1 className="h1 m-0 pt-5 pb-4 text-white">{t(`About BitTorrent`)}</h1>
			</div>
			</div>
		</div>
		<div className="container my-5 pb-3">
			<p>{t("paragraph1")}</p>

			<p>{t("paragraph2")}</p>

			<p>{t("paragraph4")}</p>
	  </div>
	</>
	)
}

export default CompanyAboutUs